import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getImageBySize, localePath } from "../../utils"
import BlogCard from "./BlogCard"
import OptionsTag from "./OptionsTag"

export default function Article({ page, id, locale, category }) {
  const data = useStaticQuery(graphql`
    {
      pages: allStrapiPage {
        nodes {
          sections {
            ... on STRAPI__COMPONENT_UI_PAGE_GALLERY {
              id
              pages {
                path
                createdAt
                createdBy {
                  data {
                    attributes {
                      firstname
                      lastname
                    }
                  }
                }
              }
            }
          }
        }
      }
      teams: allStrapiTeam {
        nodes {
          photo {
            name
            url
          }
        }
      }
    }
  `)

  const { pages, teams } = data
  const { attributes } = page.createdBy.data
  const date = new Date(page.createdAt).toLocaleDateString("en-GB")

  const components = [...pages.nodes]
    .map(p => p.sections?.filter(s => s.pages))
    .filter(p => p?.length)
    .flat(1)

  let match

  if (page)
    match = components.filter(
      c => c.pages && c.pages.find(p => p.path === page.path)
    )
  const pageOrProductIndex = match.findIndex(m => m.id === id)

  const author = teams.nodes
    .map((t, index) => {
      return {
        name: t.photo.name.match(/^(.*?)\.(png|jpg)$/)[1],
        url: t.photo.url,
      }
    })
    .filter(t => t.name === attributes.firstname)

  return (
    <BlogCard
      href={category !== "landing" ? localePath(page) : ""}
      header={
        page.cover?.ext === ".mp4" ? (
          <video
            className="h-40 lg:h-56 w-full object-cover"
            //src={page.attributes.cover.data[0].url}
            autoPlay
            muted
            loop
          />
        ) : (
          <div
            className="h-40 lg:h-56 w-full bg-cover bg-center hover:opacity-75 transition-opacity duration-300 m-2"
            style={{
              backgroundImage: `url(${getImageBySize(
                page?.cover[0],
                "small"
              )})`,
            }}
          />
        )
      }
      title={page.title}
      description={
        page.catchphrases
          ? page.catchphrases.split("\n")[pageOrProductIndex] ||
            page.description
          : page.description
      }
      date={date}
      author={author || []}
      className={"m-4"}
    ></BlogCard>
  )
}
