import React, { useState } from "react"
import { graphql } from "gatsby"
import { getImageBySize, useInterval } from "../utils"
import { Helmet } from "react-helmet"
import {
  FaFacebookF,
  FaInstagram,
  FaPinterestP,
  FaLinkedinIn,
} from "react-icons/fa"

const buttonStyle =
  "w-full rounded-full leading-none xl:leading-tight flex flex-col items-center justify-center text-black font-bold text-center py-2 xl:py-3 h-auto xl:h-16 text-sm xl:text-xl my-2 border-blue w-full  bg-white"
const iconStyle =
  "w-8 xl:w-10 h-8 xl:h-10 p-2 bg-blue rounded-full m-2 flex justify-center items-center"

function AutoHeight() {
  const [count, setCount] = useState(0)
  useInterval(() => setCount(count + 1), 4000)
  if (typeof window !== `undefined`)
    return (
      <style
        key={count}
        dangerouslySetInnerHTML={{
          __html: `
          :root { --vh: ${window.innerHeight * 0.01}px }
          .h-screen {height: calc(var(--vh, 1vh) * 100) !important}   
      `,
        }}
      />
    )
  return null
}

const Employee = ({ data }) => {
  const {
    name,
    linkedin,
    meetingUrl,
    email,
    salesforce,
    phone,
    photo,
  } = data.team

  const { instagram, facebook, pinterest } = data.variable_fr

  return (
    <div className="h-screen overflow-y-auto overflow-x-hidden flex flex-col justify-top items-center">
      <div
        className="fixed top-0 left-0 bottom-0 right-0 opacity-30"
        style={{
          backgroundImage: `url('/W.svg')`,
          backgroundSize: "cover",
          zIndex: -1,
        }}
      />
      <AutoHeight />
      <Helmet htmlAttributes={{ lang: "fr" }}>
        <meta charSet="utf-8" />
        <title>Wizito - {name}</title>
        <meta httpEquiv="Content-Language" content="fr" />
        <meta name="description" content={`Wizito - ${name}`} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="noindex,nofollow" />
        <link rel="icon" type="image/png" href="/favicon.ico" />
      </Helmet>
      <div
        className="bg-no-repeat bg-cover bg-center flex-shrink-0 mt-10"
        style={{
          backgroundImage: `url(${getImageBySize(photo, "medium")})`,
          width: "20vh",
          height: "22vh",
        }}
      />
      <div className="text-xl xl:text-4xl font-bold text-black">{name}</div>
      <div className="flex flex-col justify-center items-center max-w-xs xl:max-w-md w-full px-12">
        {phone && phone.length && (
          <a
            href={`tel:${phone}`}
            className={buttonStyle}
            style={{ borderWidth: 3, marginRight: "0.5rem" }}
          >
            Téléphone
            <small className="hidden xl:block font-thin text-sm">{phone}</small>
          </a>
        )}
        {meetingUrl && (
          <a
            href={meetingUrl}
            className={buttonStyle}
            style={{ borderWidth: 3, marginRight: "0.5rem" }}
          >
            Prendre rendez-vous
          </a>
        )}
        <a
          href={`tel:0184608160`}
          className={buttonStyle}
          style={{ borderWidth: 3 }}
        >
          SAV 9h - 23h
          <small className="hidden xl:block font-thin text-sm">
            01 84 60 81 60
          </small>
        </a>
        <a
          href={`mailto:${email}`}
          className={buttonStyle}
          style={{ borderWidth: 3 }}
        >
          Email
          <small className="hidden xl:block font-thin text-sm">{email}</small>
        </a>
        <a
          href={`https://www.canva.com/design/DAGUSSfSc2w/q3DLbZijeUVaEhS9cb-WLQ/view?utm_content=DA[…]hare&utm_medium=link2&utm_source=uniquelinks&utlId=h1d899ba8da`}
          className={buttonStyle}
          style={{ borderWidth: 3 }}
        >
          Catalogue
        </a>
        <a
          href={`/contact?origin=employee-page${
            salesforce && salesforce.length ? `&sales=${salesforce}` : ""
          }`}
          className={buttonStyle}
          style={{ borderWidth: 3 }}
        >
          Je demande un devis
        </a>
        <a
          href={`/?origin=sales-page`}
          className={buttonStyle}
          style={{ borderWidth: 3 }}
        >
          Voir le site
        </a>
      </div>
      <img
        src="/logo-noir.png"
        className="w-20 xl:w-40 my-2 xl:mt-10"
        alt="logo wizito"
      />
      <div className="flex text-white">
        <a href={facebook} className={iconStyle}>
          <FaFacebookF />
        </a>
        <a href={instagram} className={iconStyle}>
          <FaInstagram />
        </a>
        <a href={pinterest} className={iconStyle}>
          <FaPinterestP />
        </a>
        <a href={linkedin} className={iconStyle}>
          <FaLinkedinIn />
        </a>
      </div>
    </div>
  )
}

export const query = graphql`
  query TeamQuery($id: String) {
    team: strapiTeam(id: { eq: $id }) {
      name
      role
      linkedin
      email
      salesforce
      meetingUrl
      phone
      photo {
        url
        ext
      }
      id
    }
    variable_fr: strapiVariable(locale: { eq: "fr" }) {
      instagram
      facebook
      pinterest
    }
  }
`
export default Employee
